// Components
import CardContainer from '@/components/ui/CardContainer'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'ConfigMain',
  components: {
    CardContainer,
    VuetifyToolBar
  },
  data() {
    return {
      items: [
        {
          description: 'Acciones que tus establecimientos pueden realizar sobre las cartas/menús.',
          icon: 'mdi-text-box-multiple-outline',
          title: 'Cartas/Menus',
          name: 'config-menu'
        },
        {
          description:
            'Define los tipos de "raciones", "porciones", "tamaños"... que usaran tus establecimientos.',
          icon: 'mdi-numeric',
          title: 'Raciones',
          name: 'config-rations'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Parsemos los items de configuración para enviar
     * a su correspondiente pantalla o a una pantalla
     * de "debe adquirir la funcionalidad"
     *
     * @return {array}
     */
    parsedItems() {
      // All routes links
      const allRoutes = get(this, '$router.options.routes[0].children', [])

      return this.items.reduce((sumItems, item) => {
        // Obtenemos la ruta actual, si esta existe en "routesName"
        const currentRoute = allRoutes.find((route) => route.name === item.name)

        if (currentRoute) {
          // Roles de la ruta
          const currentRouteRoles = get(currentRoute, 'meta.roles', null)
          // Tenemos permiso por rol de usuario?
          const hasRoles =
            isNil(currentRouteRoles) || currentRouteRoles.indexOf(this.userData.role) > -1

          if (hasRoles) {
            sumItems.push({
              ...item,
              path: currentRoute.path
            })
          }
        }

        return sumItems
      }, [])
    }
  }
}
